import { useEffect, useRef, useState } from "react";

// import styles of this component
import styles from "./App.module.css"
import stylesNav from "../src/Components/Header/Header.module.css"

// import other components to use
import Header from './Components/Header/Header';
import Youtube from './Components/Youtube/Youtube';
import MasonryLayout from './Components/MasonryLayout/MasonryLayout';
import { InlineWidget } from "react-calendly";
import ContainerCard from './Components/ContainerCard/ContainerCard';
import Dropdown from './Components/Elements/Dropdown/Dropdown';

// import json files 
import images from "./Jsons/Images.json"
import Instagram from "./Components/Instagram/Instagram";
import MAP from "./Components/Map/Map";

import Snowfall from 'react-snowfall'
import Button from "./Components/Elements/Button/Button";
import Carousel from "./Components/CAROUSEL/Carousel";
import ContactForm from "./Components/Contact/ContactForm";
import CardCarousel from "./Components/SlidingCards/SlidingCards";
import { Helmet } from "react-helmet";
import YouTube from "react-youtube";

// App component
const Events
  = () => {
    const [userSelections, setUserSelections] = useState({
      selectedOption: "nunta", // Setează o valoare implicită validă
      select1Value: "Mai putin de 3 ore",
      select2Value: "100 - 200",
      isCheckboxChecked: false,
      additionalSelectValue: "",
      is4KChecked: false,
      isDroneChecked: false,
      isAlbumChecked: false,
    });
    const [price, setPrice] = useState(0)

    const opts = {
      width: '100%',
      height: '450px',
      playerVars: {
        // https://developers.google.com/youtube/player_parameters
        autoplay: 0,
        muted: 0,
        volume: 0,
      },
    };

    const handleSelectionsUpdate = (updatedSelections) => {
      setUserSelections((prevState) => ({
        ...prevState,
        ...updatedSelections,
      }));
    };

    // Crează un ref pentru ContactForm
    const contactFormRef = useRef(null);

    // Funcție pentru a face scroll automat
    const scrollToContactForm = () => {
      if (contactFormRef.current) {
        contactFormRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    };

    const [stateFromComponent1, setStateFromComponent1] = useState(false);

    const handleActionInComponent1 = () => {
      // Modifică starea la true
      setStateFromComponent1(true);
      scrollToContactForm();

      // După 1 secundă, resetează starea înapoi la false
      setTimeout(() => {
        setStateFromComponent1(false);
      }, 1500);  // Poți ajusta timpul în funcție de cât timp vrei să rămână activă starea
    };




    // dropdown items
    const ddItems = [
      {
        id: 1,
        title: "Featured",
        active: true,
        folder: false
      },
      {
        id: 2,
        title: "Events",
        active: false,
        folder: 'events'
      },
      {
        id: 3,
        title: "Club",
        active: false,
        folder: 'club'
      },
      {
        id: 4,
        title: "Studio & Lifestyle",
        active: false,
        folder: 'lifestyle'
      },
      {
        id: 5,
        title: "Product",
        active: false,
        folder: 'product'
      },
      {
        id: 6,
        title: "Automotive",
        active: false,
        folder: 'automotive'
      },
    ]

    const [categoryImage, setCategoryImage] = useState(images.categories.featured)
    const [currentChannelId, setCurrentChannelId] = useState('UCt1jBjgjUpdQ4HB57R7BTHA');
    const [videos, setVideos] = useState([]);

    const baseUrl = 'https://wrapapi.com/use/horoscopulazi/alinzvincacom/youtube/0.0.1?wrapAPIKey=Wf16jTpl2Y1kNiGOfraoEQwbgc6mMM1d';

    useEffect(() => {
      (async () => {
        if (currentChannelId) {
          try {
            const data = await fetch(`${baseUrl}`).then(response => response.json());
            const regex = /href="([^"]+)"/;
            const str = data.data["first video"];
            const match = regex.exec(str);

            if (match) {
              setVideos(match[1]);
            } else {
              console.log('No match found');
            }
          } catch (error) {
            //console.log(error);
          }
        }
      })();
    }, []);

    const takeDdTitle = (ddTitle) => {
      setCategoryImage(() => {
        const categoryChoose = ddTitle.folder

        // return categoryChoose ? imageList : [...images.categories['featured']]

        switch (ddTitle.id) {
          case 1: {
            return [...images.categories['featured']];
            break;
          }
          case 2: {
            const imagesFolder = require.context('../public/img/events', true);
            const imageList = imagesFolder.keys().map(image => imagesFolder(image)).reverse();

            return imageList;
            break;
          }
          case 3: {
            const imagesFolder = require.context('../public/img/club', true);
            const imageList = imagesFolder.keys().map(image => imagesFolder(image)).reverse();

            return imageList;
            break;
          }
          case 4: {
            const imagesFolder = require.context('../public/img/studio', true);
            const imageList = imagesFolder.keys().map(image => imagesFolder(image)).reverse();

            return imageList;
            break;
          }
          case 5: {
            const imagesFolder = require.context('../public/img/product/compressed/pictures', true);
            const imageList = imagesFolder.keys().map(image => imagesFolder(image)).reverse();

            return imageList;
            break;
          }
          case 6: {
            const imagesFolder = require.context('../public/img/automotive/compressed/pictures', true);
            const imageList = imagesFolder.keys().map(image => imagesFolder(image)).reverse();

            return imageList;
            break;
          }
          default: {
            return [...images.categories['featured']];
            break;
          }
        }
      })
    }

    return (
      <>
        <Helmet>
          <title>Alin Zvinca - Events Experience</title>
          <meta name="description" content="Retraieste povestea evenimentelor tale mereu! Alin Zvinca fotograf si videograf nunta, botez, majorat, corporate" />
        </Helmet>
        <div className={`containerBody events`}>
          <Header events />
          <div className={styles['appointment-area']} >
            <div className="price-estimate">
              <h3 className={stylesNav["heading-header-second-title-events"]}>Estimare Pret</h3>
              <CardCarousel
                userSelections={userSelections}
                onSelectionsUpdate={handleSelectionsUpdate}
                updatePrice={setPrice}
                onAction={handleActionInComponent1}
              />
            </div>
            <div className="contact-form" ref={contactFormRef}>
              <h3 className={stylesNav["heading-header-second-title-events"]}>Contact</h3>
              <ContactForm userSelections={userSelections} price={price} state={stateFromComponent1} />
            </div>
          </div>
          <div className={styles['contact-area']} >
            <Button theme="" className={styles['contact-button']} target="_blank" onClick={() => window.location = 'https://wa.link/298mdb'} >Mai multe detalii? Hai pe Whatsapp!</Button>
          </div>
          <h3 className={stylesNav["heading-header-second-title-events"]}>VIDEO SHOWCASE</h3>
          <YouTube className={styles['youtube-video']} videoId={'NqLEhEPXDl8'} opts={opts} muted={true} volume={0} autoplay={false} />
          <YouTube className={styles['youtube-video']} videoId={'zolGo1H4QTg'} opts={opts} muted={true} volume={0} autoplay={false} />
          <div style={{ width: '100%', backgroundColor: '#121212' }}><iframe src="https://player.vimeo.com/video/1045075255?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" width="100%" height="500" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write; encrypted-media" title="Botez Anastasia Ioana"></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>
          <h3 className={stylesNav["heading-header-second-title-events"]}>PHOTO SHOWCASE</h3>
          <Carousel events />

          <MAP christmas />


          <div className="flex justify-content-center" style={{ marginTop: "50px", padding: '50px' }}>
            <ContainerCard>
              <div className={`${styles["gallery-setting"]} flex justify-content-between align-items-center`}>
                <div className={styles["gallery-titles"]}><h1 id="bangers">Ce mai pot face?</h1></div>
                <div className={styles["break"]}></div>
                <div className={styles["tools"]}>
                  <Dropdown title="Filter" items={ddItems} liftingDdTextUp={takeDdTitle} />
                  <a href="https://wa.link/298mdb" target="_blank" className={styles["btn-mix-noborder"]} data-text="Ai alte idei?">Ai alte idei?</a>
                </div>
              </div>
              <MasonryLayout images={categoryImage} />
            </ContainerCard>
          </div>
        </div>
      </>
    )
  }

export default Events
