import { useEffect, useState } from "react";
import styles from "./BrickLayout.module.css"

const BrickLayout = ({ christmas = false, events = false, studio = false }) => {
  const [clickCount, setClickCount] = useState(0); // Contor pentru click-uri
  const [timeoutId, setTimeoutId] = useState(null); // Pentru a curăța timeout-ul

  // Resetarea contorului de click-uri dacă nu se face un click într-un interval scurt
  useEffect(() => {
    if (clickCount >= 3) {
      alert("Te iubesc!");
      setClickCount(0); // Resetăm contorul după ce s-a declanșat alerta
    } else if (clickCount > 0) {
      // Resetăm contorul după 2 secunde de inactivitate
      if (timeoutId) clearTimeout(timeoutId);
      const id = setTimeout(() => setClickCount(0), 500); // Resetare după 2 secunde
      setTimeoutId(id);
    }
  }, [clickCount]);

  const handleClick = () => {
    setClickCount((prev) => prev + 1); // Incrementăm contorul de click-uri
  };

  if (events) {
    return (<div className={styles["brick-layout"]}>
      <div className={styles["brick-column"]}>
        <img src="img/events/maj-bianca1.jpeg" alt="" />
        <img src="img/events/botez1.jpeg" alt="" />
        <img src="img/events/events3.jpeg" alt="" />
        <img src="img/events/wed4.jpg" alt="" />
      </div>
      <div className={styles["brick-column"]}>
        <img src="img/events/maj-bianca7.jpeg" alt="" />
        <img src="img/events/wed1.jpg" alt="" />
        <img src="img/events/botez2.jpeg" alt="" />
        <img src="img/events/majorate14.jpeg" alt="" />
      </div>
      <div className={styles["brick-column"]}>
        <img src="img/events/maj-bianca2.jpeg" alt="" />
        <img src="img/events/majorate2.jpeg" alt="" />
        <img src="img/events/botez6.jpeg" alt="" />
        <img src="img/events/maj-bianca8.jpeg" alt="" />
      </div>
    </div>)
  }
  if (christmas) {
    return (<div className={styles["brick-layout"]}>
      <div className={styles["brick-column"]}>
        <img src="img/christmas/christmas3.jpeg" alt="" />
        <img src="img/christmas/Santa-7.jpeg" alt="" />
        <img src="img/christmas/christmas15.jpeg" alt="" />
        <img src="img/christmas/christmas12.jpeg" alt="" />
      </div>
      <div className={styles["brick-column"]}>
        <img src="img/christmas/christmas5.jpeg" alt="" />
        <img src="img/christmas/diana153.jpeg" alt="" />
        <img src="img/christmas/christmas14.jpeg" alt="" />
        <img src="img/christmas/christmas8.jpeg" alt="" />
      </div>
      <div className={styles["brick-column"]}>
        <img src="img/christmas/christmas9.jpeg" alt="" />
        <img src="img/christmas/christmas10.jpeg" alt="" />
        <img src="img/christmas/christmas11.jpeg" alt="" />
        <img src="img/christmas/christmas4.jpeg" alt="" />
      </div>
    </div>)
  }
  if (studio) {
    return (
      <div className={styles["brick-layout"]}>
        <div className={styles["brick-column"]}>
          <img src="img/christmas/insta2.jpeg" alt="" />
          <img src="img/studio/valentines7.jpeg" alt="" />
          <img src="img/product/compressed/pictures/product1.jpeg" alt="" />
          <img src="img/product/compressed/pictures/revuele1.jpeg" alt="" />
        </div>
        <div className={styles["brick-column"]}>
          <img src="img/studio/valentines5.jpeg" alt="" />
          <img src="img/studio/valentines2.jpeg" alt="" />
          <img src="img/lifestyle/compressed/pictures/Diana-27.jpeg" alt="" />
          <img src="img/studio/studio10.jpeg" alt="" />
        </div>
        <div className={styles["brick-column"]}>
          <img src="img/christmas/ant.jpeg" alt=""
            onClick={handleClick} />
          <img src="img/lifestyle/compressed/pictures/lifestyle6.jpeg" alt="" />
          <img src="img/lifestyle/compressed/pictures/lifestyle8.jpeg" alt="" />
          <img src="img/lifestyle/compressed/pictures/Diana.jpeg" alt="" />
        </div>
      </div>
    )
  }
  return (
    <div className={styles["brick-layout"]}>
      <div className={styles["brick-column"]}>
        <img src="img/club/club15.jpeg" alt="" />
        <img src="img/events/majorate6.jpeg" alt="" />
        <img src="img/product/compressed/pictures/product1.jpeg" alt="" />
        <img src="img/studio/maria9.jpeg" alt="" />
      </div>
      <div className={styles["brick-column"]}>
        <img src="img/automotive/compressed/pictures/Alex8R-19.jpeg" alt="" />
        <img src="img/automotive/compressed/pictures/automotive30.jpeg" alt="" />
        <img src="img/lifestyle/compressed/pictures/Diana-27.jpeg" alt="" />
        <img src="img/events/majorate4.jpeg" alt="" />
      </div>
      <div className={styles["brick-column"]}>
        <img src="img/christmas/ant.jpeg" alt=""
          onClick={handleClick} />
        <img src="img/lifestyle/compressed/pictures/lifestyle6.jpeg" alt="" />
        <img src="img/events/maj-bianca8.jpeg" alt="" />
        <img src="img/studio/insta8.jpeg" alt="" />
      </div>
    </div>
  )
}

export default BrickLayout